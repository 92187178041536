import { useContext, type ReactNode, useState, useEffect } from 'react';
import classes from './order.module.scss';
import { twMerge } from 'tailwind-merge';
import UiFilledButton from '../Ui/UiFilledButton';
import { buildApiString } from '../../shared/lib/helpers';
import { useTranslation } from 'react-i18next';
import { useYandexHit } from '../../processes/providers/yandexMetrika/hooks/useYandexHit';
import { EYMActions } from '../../processes/providers/yandexMetrika/entity';
import { useStore } from '../../shared/hooks/useStore';
import { EOrderConfirmStatus } from '../../processes/providers/authorization';
import { ModalContext } from '../../context/modal/modalContext';
import LoadingCircle from '../../shared/components/loadingCircle';
import { useIsMobile } from '~/shared/hooks/useIsMobile';

interface IOrderProps {
    header?: ReactNode;
    info: ReactNode;
    total?: ReactNode;
    link?: ReactNode;
    feedback?: ReactNode;
}

const Order = ({
    header,
    info,
    total,
    link, 
    feedback
}: IOrderProps) => {
    const { t } = useTranslation('components');
    const { ymHit } = useYandexHit();
    const { COrder } = useStore();
    const { order_info } = COrder.order;
    const { waiting_confirm } = order_info;
    const { show } = useContext(ModalContext);
    const requiredConfirm = waiting_confirm === EOrderConfirmStatus.REQUIRES_CONFIRMATION;
    const cancelledOrder = waiting_confirm === EOrderConfirmStatus.CANCELED;
    const [isReceiptLoaded, setIsReceiptLoaded] = useState(false);
    const [receiptLink, setReceiptLink] = useState('');
    const isMobile = useIsMobile();

    const modalProps = (href: string) => ({
        content: (
            <>
                <img style={{ alignSelf: 'start' }} src={href} onLoad={() => setIsReceiptLoaded(true)} />
                {!isReceiptLoaded && <LoadingCircle />}
            </>
        ),
        fullScreen: isMobile,
    });

    useEffect(() => {
        if (isReceiptLoaded) {
            show(modalProps(receiptLink));
            setIsReceiptLoaded(false);
        }
    }, [isReceiptLoaded]);

    const handleToReceipt = (href: string) => {
        ymHit(EYMActions.OPEN_RECEIPT_WINDOW);

        show(modalProps(href));
        setReceiptLink(href);
    };

    return (
        <>
            {header}
            { link || requiredConfirm ? (
                <div className={twMerge(classes.buttons, 'flex-col flex md:flex-row justify-between', requiredConfirm && 'h-[200px] md:h-[100px]', cancelledOrder && 'h-0]')}>
                    {link}
                    {requiredConfirm && (
                        <UiFilledButton
                            onClick={handleToReceipt}
                            href={buildApiString(order_info.receipt_link)}
                            text={t('order.total.viewReceipt')}
                            classNames='basis-full md:basis-1/2 ml-0 md:ml-[30px] !block h-[100px] mt-4 md:mt-0 self-start md:self-center button-big button-empty'
                        />
                    )}
                </div>
            ) : null }
            {feedback && (
                <div className={classes.line} style={{ marginTop: '40px', marginBottom: '40px' }}>
                    {feedback}
                </div>
            )}

            <div className={`${classes.box} ${!total ? classes.full : ''}`}>
                {info}
                {total}
            </div>
        </>
    );
};

export default Order;
