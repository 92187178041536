import classes from './header.module.scss';
import PhoneIcon from '@icons/PhoneIcon';
import Menu from '@components/Menu/Menu';
import { observer } from 'mobx-react';
import { useStore } from '~/shared/hooks/useStore';
import { buildApiString } from '~/shared/lib/helpers';
import { useYandexHit } from '~/processes/providers/yandexMetrika/hooks/useYandexHit';
import { EYMActions } from '~/processes/providers/yandexMetrika/entity';
import LanguageSelector from '../../shared/components/LanguageSelector';
import { useLocation } from 'react-router-dom';
import { Notification } from '@components/TechnologistsComments';
import useIPAndLink from '~/shared/hooks/useIPAndLink';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '~/shared/hooks/useIsMobile';
// import QuestionIcon from '~/assets/icons/question';

const Header = () => {
    const { CCompanyInfo } = useStore();
    const { logo, title, phone, website } = CCompanyInfo.companyInfo;
    const { ymHit } = useYandexHit();
    const location = useLocation();
    const { showButton, buttonLink } = useIPAndLink(['62.141.124.39', '148.251.44.197', '157.90.89.177']);
    const isMobile = useIsMobile();
    const { t } = useTranslation('common');
    
    const handleClickPhone = () => {
        ymHit(EYMActions.HEADER_PHONE);
    };

    const handleClickLogo = () => {
        ymHit(EYMActions.HEADER_LOGO);
    };

    const formatUrl = (addr: string): string => {
        try {
            new URL(addr);

            return addr;
        } catch (error) {
            const newURL = `https://${addr}`;

            try {
                new URL(newURL);

                return newURL;
            } catch (error) {
                return '';
            }
        }
    };

    return (
        <header className={classes.box}>
            <div className={`container ${classes.container}`}>
                {location.pathname !== '/' ? (
                    <>
                        {showButton && (
                            <a href={buttonLink} className="block border-b mx-auto px-4 py-2 text-center no-underline text-white bg-[#0073B1] rounded text-sm whitespace-nowrap">
                                {t('errorInfo.button')}
                            </a>
                        )}
                        {/* Меняем порядок блоков в зависимости от isMobile */}
                        {!isMobile ? (
                            <>
                                <div>
                                    <Menu />
                                </div>
                                <div className="text-center">
                                    <a onClick={handleClickLogo} href={formatUrl(website)} className={classes.logo}>
                                        <img
                                            className="h-[50px] max-h-[50px] md:min-h-[60px] lg:min-h-[80px] md:max-h-[80px] object-contain"
                                            src={logo ? buildApiString(logo) : './android-chrome-512x512.png'}
                                            alt={title}
                                        />
                                    </a>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="text-center">
                                    <a onClick={handleClickLogo} href={formatUrl(website)} className={classes.logo}>
                                        <img
                                            className="h-[50px] max-h-[50px] md:min-h-[60px] lg:min-h-[80px] md:max-h-[80px] object-contain"
                                            src={logo ? buildApiString(logo) : './android-chrome-512x512.png'}
                                            alt={title}
                                        />
                                    </a>
                                </div>
                                <div>
                                    <Menu />
                                </div>
                            </>
                        )}
                        <div className="text-right flex justify-end items-center sm:space-x-5 space-x-2">
                            <a className={classes.phone + ' text-sm lg:text-lg'} onClick={handleClickPhone} href={`tel:${phone}`}>
                                <span className={classes.phoneIcon}>
                                    <PhoneIcon />
                                </span>
                                <span className={classes.phoneText}>
                                    {phone}
                                </span>
                            </a>
                            <Notification />
                            <LanguageSelector />
                            {/* <a className='cursor-pointer' href={ window.location.origin } target="_blank" rel="noreferrer">
                                <QuestionIcon />
                            </a> */}
                        </div>
                    </>
                ) : (
                    <div className="text-center">
                        <a onClick={handleClickLogo} href={'https://order.dry.care'} className={classes.logo}>
                            <img
                                className="h-[50px] max-h-[50px] md:min-h-[60px] lg:min-h-[80px] md:max-h-[80px] object-contain"
                                src={logo ? buildApiString(logo) : './android-chrome-512x512.png'}
                                alt={title}
                            />
                        </a>
                    </div>
                )}
            </div>
        </header>
    );
};

export default observer(Header);
