import CAuthorization from '~/processes/providers/authorization/store/index';
import CCompanyInfo from '~/shared/store/companyInfo';
import CClientInfo from './clientInfo';
import CLinks from './links';
import COrder from './order';
import CLocalization from './localization';
import CRecommendations from './recommendations';
import CNotifications from './notifications';
import CSettings from './settings';

/**
 * Глобальное храналищие, которое работает внутри контекста реакта
 */
export const STORE_BUILDER = {
    COrder: new COrder(),
    CLinks: new CLinks(),
    CCompanyInfo: new CCompanyInfo(),
    CClientInfo: new CClientInfo(),
    CAuthorization: new CAuthorization(),
    CLocalization: new CLocalization(),
    CRecommendations: new CRecommendations(),
    CNotifications: new CNotifications(),
    CSettings: new CSettings(),
};
