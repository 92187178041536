import { makeAutoObservable } from 'mobx';
import type { ISettings } from '~/processes/providers/authorization';

const initialSettings: ISettings = {
    partial_payment: 0,
    referal_system: 0,
    payment_method: 'qr',
};

/** Класс для раюоты с пользовательскими настройками */
export default class CSettings {
    private _settings: ISettings = { ...initialSettings };

    constructor() {
        makeAutoObservable(this);
    }

    public get settings(): ISettings {
        return this._settings;
    }

    public setSettings(settings: ISettings): void {
        this._settings = settings;
    }
}
