import { useMemo, type FC } from 'react';
import classes from './PaymentButton.module.scss';
import { useTranslation } from 'react-i18next';
import decodeHash from '../lib/decodeHash';
import { PaymentSystems } from '../lib/PaymentSystems';
import { useGeneralPayment } from '~/components/Payment/hooks/useGeneralPayment';

interface PaymentButtonProps {
    generalPayHash: string;
    disabled: boolean;
    isLoading?: boolean;
    paymentSum: number;
    onClick?: (session: string) => void;
}

const PaymentButton: FC<PaymentButtonProps> = ({ disabled, generalPayHash, paymentSum }) => {
    const { t } = useTranslation('components');
    const paymentSystemId =  useMemo(() => JSON.parse(decodeHash(generalPayHash)).payment_system_id, []);
    const [generalPayment, isLoadingGeneral] = useGeneralPayment(paymentSum);

    const onClick = () => {
        generalPayment(generalPayHash);
    };

    if (paymentSystemId === PaymentSystems.IDRAM) {
        return (
            <button
                onClick={onClick}
                disabled={disabled || isLoadingGeneral}
                className={`button button-great ${classes.idram} ${!isLoadingGeneral ? classes.idram_background : ''}`}
            >
                { isLoadingGeneral ? (
                    t('payments.information.generalPay.button.loading')
                ) : ''}
            </button>
        );
    }

    if (paymentSystemId === PaymentSystems.ARCA) {
        return (
            <button
                onClick={onClick}
                disabled={disabled || isLoadingGeneral}
                className={`button button-great ${classes.arca} ${!isLoadingGeneral ? classes.arca_background : ''}`}
            >
                { isLoadingGeneral ? (
                    t('payments.information.generalPay.button.loading')
                ) : ''}
            </button>
        );
    }

    return (
        <button
            onClick={onClick}
            disabled={disabled || isLoadingGeneral}
            className="button button-great"
        >
            {isLoadingGeneral
                ?
                t('payments.information.generalPay.button.loading')
                :
                t('payments.information.generalPay.button.title')
            }
        </button>    
    );
};

export default PaymentButton;
