import { useCallback, useEffect, useMemo, useState } from 'react';
import { EPaymentsMode } from '~/processes/providers/authorization';
import { useStore } from '~/shared/hooks/useStore';

enum ELimitValue {
    MAX = 'max',
    MIN = 'min',
}

enum EValueType {
    ABSOLUTE = 'absolute',
    PERCENTAGE = 'percentage',
}

/** Используется для управления суммой платежа.
 * Добавляет возможность управления суммой частичного платежа
 * */
export const usePaymentSum = () => {
    const { COrder, CSettings } = useStore();
    const { need_to_pay } = COrder.order.order_info;
    const { payments_sum } = COrder.order.order_payments;
    const [paymentSum, setPaymentSum] = useState(0);
    const { partial_payment } = CSettings.settings;

    const isFirstPayment = useMemo(() => {
        const sumArray = Object.values(payments_sum);
        return !sumArray.reduce((a, b) => a + b, 0);
    }, [payments_sum]);

    const getAbsoluteOrPercentage = useCallback((value: number): EValueType => {
        if (Number.isInteger(value)) {
            return EValueType.ABSOLUTE;
        }

        return EValueType.PERCENTAGE;
    }, []);

    const calculateLimitValue = useCallback((type: ELimitValue) => {
        if (!isFirstPayment) {
            return need_to_pay;
        }

        if (!partial_payment) {
            return need_to_pay;
        }

        const valueType = getAbsoluteOrPercentage(partial_payment.value);
        const isEqualOrLess = partial_payment.type === EPaymentsMode.EQUAL || partial_payment.type === EPaymentsMode.LESS;
        const isEqualOrMore = partial_payment.type === EPaymentsMode.EQUAL || partial_payment.type === EPaymentsMode.MORE;

        switch (valueType) {
        case EValueType.ABSOLUTE: {
            if (type === ELimitValue.MAX) {
                return isEqualOrLess ? partial_payment.value : need_to_pay;
            }

            if (type === ELimitValue.MIN) {
                return isEqualOrMore ? partial_payment.value : 0;
            }

            return need_to_pay;
        }

        case EValueType.PERCENTAGE: {
            if (type === ELimitValue.MAX) {
                return isEqualOrLess ? partial_payment.value * need_to_pay : need_to_pay;
            }

            if (type === ELimitValue.MIN) {
                return isEqualOrMore ? partial_payment.value * need_to_pay : 0;
            }

            return need_to_pay;
        }}
    }, []); 

    const maxSum = useMemo(() => calculateLimitValue(ELimitValue.MAX), [need_to_pay, partial_payment]);
    const minSum = useMemo(() => calculateLimitValue(ELimitValue.MIN), [need_to_pay, partial_payment]);

    const showInput = useMemo(() => {
        if (partial_payment && partial_payment.type !== EPaymentsMode.EQUAL && isFirstPayment) {
            return true;
        }

        return false;
    }, [partial_payment]);

    const getDefaultSum = useCallback(() => {
        if (!partial_payment) {
            return need_to_pay;
        }

        if (!isFirstPayment) {
            return need_to_pay;
        }

        const isEqualOrLess = partial_payment.type === EPaymentsMode.EQUAL || partial_payment.type === EPaymentsMode.LESS;
        const isMore = partial_payment.type === EPaymentsMode.MORE;
        const valueType = getAbsoluteOrPercentage(partial_payment.value);

        if (isEqualOrLess && valueType === EValueType.ABSOLUTE) {
            return partial_payment.value;
        }

        if (isEqualOrLess && valueType === EValueType.PERCENTAGE) {
            return partial_payment.value * need_to_pay;
        }

        if (isMore) {
            return need_to_pay;
        }

        return need_to_pay;
    }, []);

    useEffect(() => {
        setPaymentSum(getDefaultSum());
    }, [getDefaultSum]);

    const setCorrectSum = useCallback((sum: number, isOnBlur = false) => {
        if (sum > maxSum) {
            setPaymentSum(maxSum);
        }

        if (sum < minSum) {
            setPaymentSum(minSum);
        }

        if (!isOnBlur) {
            setPaymentSum(sum);
        }
    }, [maxSum, minSum]);

    const onChangeSum = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        setCorrectSum(value);
    }, []);

    const onBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        setCorrectSum(value, true);
    }, [paymentSum]);

    return {
        paymentSum,
        partial_payment,
        maxSum,
        minSum,
        showInput,
        onChangeSum,
        onBlur,
    };
};
