import { observer } from 'mobx-react';
import { type ReactNode, useState, useRef, useEffect } from 'react';
import { useStore } from '~/shared/hooks/useStore';
import classes from './order.module.scss';
import { useStatusData } from './hooks/useStatusData';
import { useTranslation } from 'react-i18next';
import { useDeliveryDate } from './hooks/useDeliveryDate';
import QuestionIcon from '~/assets/icons/question';

const STATUS_LENGTH_FOR_SMALL_SIZE = 9;

interface IOrderInfoProps {
    button?: ReactNode;
}

const OrderInfo = ({
    button,
}: IOrderInfoProps) => {
    const { COrder, CCompanyInfo } = useStore();
    const { order_info } = COrder.order;
    const { t } = useTranslation('components');
    const { deliveryDate } = useDeliveryDate();
    const statusData = useStatusData();

    const hasDeliveryPlan = COrder.hasDeliveryPlan();
    const deliveryAddress = COrder.getDeliveryAddress();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);
    const toggleModal = () => setIsModalOpen((prev) => !prev);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                setIsModalOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <ul className={`${classes.list} ${button ? classes.withButton : ''}`}>
            <li>
                <span className={classes.key}>
                    {t('order.info.status')}
                </span>
                <span className={`${classes.value} ${classes.status}`}>
                    <img src={statusData.icon} />
                    {statusData.title.length >= STATUS_LENGTH_FOR_SMALL_SIZE ? (
                        <small>{t(statusData.title)}</small>
                    ) : (
                        t(statusData.title)
                    )}
                </span>
            </li>
            <li>
                <span className={classes.key}>
                    {t('order.info.dateTake')}
                </span>
                <span className={classes.value}>
                    {order_info.date_in}
                </span>
            </li>
            {!!deliveryDate && (
                <li>
                    <span className={classes.key}>
                        {t('order.info.dateDelivery')}
                    </span>
                    <span className={classes.value}>
                        {deliveryDate.date} <br />
                        {deliveryDate.time}
                    </span>
                </li>
            )}
            {!deliveryDate && (
                <li>
                    <span className={classes.key}>
                        {t('order.info.dateGive')}
                    </span>
                    <span className={classes.value}>
                        {order_info.date_out}
                    </span>
                    <span className={classes.helpText}>
                        {t('order.info.dateChangeInformation')}
                    </span>
                </li>
            )}
            <li>
                <div className={classes.deliveryInfo}>
                    <span className={classes.deliveryTitle}>
                        {hasDeliveryPlan
                            ? t('order.info.adresDelivery', { adr: deliveryAddress })
                            : t('order.info.deliveryPoint', {
                                point: order_info.point_of_delivery_name,
                                adr: deliveryAddress
                            })}
                    </span>
                    <div className={classes.questionContainer} ref={modalRef}>
                        <button onClick={toggleModal} className={classes.questionIcon}>
                            <QuestionIcon/>
                        </button>
                        {isModalOpen && (
                            <div className={classes.modalContent}>
                                <p>{t('order.info.deliveryErrorAdress')}</p>
                                <p>
                                    <a href={`tel:${CCompanyInfo.companyInfo.phone}`} className={classes.phoneLink}>
                                        {t('order.info.deliveryErrorPhone')}
                                    </a>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </li>
            {button && (
                <li className={classes.button}>
                    {button}
                </li>
            )}
        </ul>
    );
};

export default observer(OrderInfo);
