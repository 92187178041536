import Feedback from '@components/Feedback/Feedback';
import Recommendations from '@components/Recommendations';
import Stocks from '@components/Stock/Stocks';
import Section from '@components/Section/Section';
import OrderHeader from '@components/Order/OrderHeader';
import OrderInfo from '@components/Order/OrderInfo';
import OrderTotal from '@components/Order/OrderTotal';
import Order from '@components/Order/Order';
import ArrowRightIcon from '@icons/ArrowRightIcon';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from '~/processes/routes';
import { useStore } from '~/shared/hooks/useStore';
import { useYandexHit } from '~/processes/providers/yandexMetrika/hooks/useYandexHit';
import { EYMActions } from '~/processes/providers/yandexMetrika/entity';
import { useTranslation } from 'react-i18next';
import titleIcon from '~/assets/icons/thumb-up.svg';
import { EOrderStatus } from '~/components/Order/entity';

const OrderConfirmedPage = () => {
    const navigate = useNavigate();
    const { COrder, CCompanyInfo, CLinks, CClientInfo, CRecommendations } = useStore();
    const { t } = useTranslation('components');
    const { order_payments, order_info } = COrder.order;
    const { bonus, deposit } = CClientInfo.clientInfo;
    const { cloud_payment, qr, bonus_payment, deposit_payment, general_pay } = CLinks.links;
    const { promotions } = CCompanyInfo.companyInfo;
    const { ymHit } = useYandexHit();

    const isNeedPayment = (order_info.order_sum - order_payments.payments_sum.all_sum) > 0;
    const isHaveBonus = (bonus_payment && bonus > 0);
    const isHaveDeposit = (deposit_payment && deposit > 0);
    const isConnectedPaymentSystem = (cloud_payment || qr || general_pay);
    const isShowPaymentButton = isNeedPayment && (isHaveBonus || isHaveDeposit || isConnectedPaymentSystem);

    const isShowPromotions = promotions.length > 0;
    const isOrderDelivered = EOrderStatus.ISSUED === order_info.status_id;
    const handleToDetails = () => {
        navigate(ERoutes.DETAIL);
        ymHit(EYMActions.OPEN_DETAILS_OF_ORDER);
    };

    const handleCloseRecommendations = () => {
        CRecommendations.setShowRecommendations(false);
        ymHit(EYMActions.HIDE_RECOMMENDATIONS);
    };

    return (
        <>
            <Section>
                <Order
                    header={<OrderHeader />}
                    feedback={isOrderDelivered && <Feedback />}
                    info={<OrderInfo />}
                    total={(
                        <OrderTotal
                            button={isShowPaymentButton && (
                                <button className="button button-fill" onClick={() => navigate(ERoutes.PAYMENT)}>
                                    {t('pages.confirmPage.buttonPaymentOrder')}
                                </button>
                            )}
                        />
                    )}
                    link={(
                        <a className="button button-more w-full" onClick={handleToDetails}>
                            {t('pages.confirmPage.viewDetailsOrder')} <ArrowRightIcon />
                        </a>
                    )}
                />
            </Section>
            { CRecommendations.showRecommendations
                && CRecommendations.recommendations
                && CRecommendations.recommendations?.recommendations.length > 0
                && (
                    <Section
                        title={ t('components:recommendations.title') as string }
                        icon={titleIcon}
                        handleClose={handleCloseRecommendations}
                    >
                        <Recommendations />
                    </Section>  
                )}

            {isShowPromotions && (
                <Section title={t('pages.confirmPage.title') as string}>
                    <Stocks />
                </Section>
            )}

            {!isOrderDelivered && (
                <Section>
                    <Feedback />
                </Section>
            )}
        </>
    );
};

export default observer(OrderConfirmedPage);
