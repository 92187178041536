import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';


const useIPAndLink = (desiredIPs: string[]) => {
    const [ip, setIp] = useState<string | null>(null);
    const [showButton, setShowButton] = useState(false);
    const [buttonLink, setButtonLink] = useState('');
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const hash = searchParams.get('hash');

        if (!hash) {
            console.log('Hash not found in URL');
            return;
        }

        const decodedHash = decodeURIComponent(hash);

        fetch(`https://himinfo.ru/LinkOrder/LinkOrderApi.php?type=ip&session=${encodeURIComponent(decodedHash)}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data && data.ip_address) {
                    setIp(data.ip_address);
                    if (desiredIPs.includes(data.ip_address)) {
                        setShowButton(true);
                        const currentDate = new Date().toISOString().split('T')[0];
                        const newLink = `/api/LinkOrder/LinkOrderApi.php?type=log&date=${currentDate}&session=${encodeURIComponent(decodedHash)}`;
                        setButtonLink(newLink);
                    }
                } else {
                    console.error('IP not found in response');
                }
            })
            .catch(error => {
                console.error('Error fetching IP address:', error);
            });
    }, [location.search, desiredIPs]);

    return { ip, showButton, buttonLink };
};

export default useIPAndLink;