import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import type { IOrder } from '~/processes/providers/authorization';

const initialOrder: IOrder = {
    error: 0,
    dor_id: '',
    order_services: [],
    order_payments: {
        payments: [],
        payments_sum: {
            sum_cash: 0,
            sum_bank: 0,
            sum_card: 0,
            sum_bonus: 0,
            sum_deposit: 0,
            all_sum: 0
        }
    },
    order_comments: [],
    order_plans: [],
    order_signs: [],
    order_info: {
        count_of_services: 0,
        date_in: '',
        date_out: '',
        discount: 0,
        doc_num: '',
        currency: '',
        need_to_pay: 0,
        order_sum: 0,
        point_of_delivery_adr: '',
        point_of_delivery_name: '',
        is_on_delivery_point: 0,
        receipt_link: '',
        sclad_hours: '',
        status_id: -1,
        waiting_confirm: -1,
        is_not_confirmed: 0,
        dor_id: -1,
        barcode: 0
    },
};

/** 
 * Класс для работы с информацией о заказе
 */
export default class COrder {
    private _order: IOrder = { ...initialOrder };

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'COrder',
            // @ts-ignore
            properties: ['_order'],
            storage: sessionStorage,
            expireIn: 86400000,
        }).then();
    }

    /** Возвращает данные о заказе */
    public get order(): IOrder {
        return this._order;
    }

    /**
     * Установить новые данные о заказе
     * @param order - данные о заказе
     */
    public setOrder(order: IOrder): void {
        if (!Array.isArray(order.order_services)) {
            order.order_services = Object.values(order.order_services);
        }

        this._order = order;
    }
    public hasDeliveryPlan(): boolean {
        return this._order.order_plans.some(plan => Number(plan.tp) === 2);
    }

    public getDeliveryAddress(): string {
        const deliveryPlan = this._order.order_plans.find(plan => Number(plan.tp)  === 2);
        return deliveryPlan ? decodeURIComponent(deliveryPlan.address) : this._order.order_info.point_of_delivery_adr;
    }
}